import * as _ from 'lodash';
import { Mutex } from 'async-mutex';
import { EventEmitter } from 'events';
import { TypedError } from 'typed-error';

import * as jwt from 'jsonwebtoken';
import * as Auth0 from 'auth0-js';
import { Auth0LockPasswordless } from '@httptoolkit/auth0-lock';
const auth0Dictionary = require('@httptoolkit/auth0-lock/lib/i18n/en').default;
import * as dedent from 'dedent';

import { lightTheme } from '../../styles';
import { reportError } from '../../errors';

import { SubscriptionPlanCode, getSubscriptionPlanCode } from './subscriptions';
import { attempt } from '../../util/promise';

// const AUTH0_CLIENT_ID = 'KAJyF1Pq9nfBrv5l3LHjT9CrSQIleujj';
// const AUTH0_DOMAIN = 'login.httptoolkit.tech';

// We read data from auth0 (via a netlify function), which includes
// the users subscription data, signed into a JWT that we can
// validate using this public key.
// const AUTH0_DATA_PUBLIC_KEY = `
// -----BEGIN PUBLIC KEY-----
// MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAzRLZvRoiWBQS8Fdqqh/h
// xVDI+ogFZ2LdIiMOQmkq2coYNvBXGX016Uw9KNlweUlCXUaQZkDuQBmwxcs80PEn
// IliLvJnOcIA9bAJFEF36uIwSI/ZRj0faExanLO78cdIx+B+p69kFGlohQGzJmS1S
// v/IYYu032hO+F5ypR+AoXn6qtGGLVN0zAvsvLEF3urY5jHiVbgk2FWD3FWMU3oBF
// jEEjeSlAFnwJZgeEMFeYni7W/rQ8seU8y3YMIg2UyHpeVNnuWbJFFwGq8Aumg4SC
// mCVpul3MYubdv034/ipGZSKJTwgubiHocrSBdeImNe3xdxOw/Mo04r0kcZBg2l/b
// 7QIDAQAB
// -----END PUBLIC KEY-----
// `;

export class RefreshRejectedError extends TypedError {
    constructor(response: { description: string }) {
        super(`Token refresh failed with: ${response.description}`);
    }
}




let tokens: {
    refreshToken: string;
    accessToken: string;
    accessTokenExpiry: number; // time in ms
} | null;

attempt(
    // ! because actually parse(null) -> null, so it's ok
    () => JSON.parse(localStorage.getItem('tokens')!)
)
    .then((t) => tokens = t)
    .catch((e) => {
        console.log('Invalid token', localStorage.getItem('tokens'), e);
        reportError('Failed to parse tokens');
    });

const tokenMutex = new Mutex();

function setTokens(newTokens: typeof tokens) {
    return tokenMutex.runExclusive(() => {
        tokens = newTokens;
        localStorage.setItem('tokens', JSON.stringify(newTokens));
    });
}




export type SubscriptionStatus = 'active' | 'trialing' | 'past_due' | 'deleted';

type AppData = {
    email: string;
    subscription_status?: SubscriptionStatus;
    subscription_id?: number;
    subscription_plan_id?: number;
    subscription_expiry?: number;
    update_url?: string;
    cancel_url?: string;
    last_receipt_url?: string;
    feature_flags?: string[];
}

type SubscriptionData = {
    id: number;
    status: SubscriptionStatus;
    plan: SubscriptionPlanCode;
    expiry: Date;
    updateBillingDetailsUrl?: string;
    cancelSubscriptionUrl?: string;
    lastReceiptUrl?: string;
};

export type User = {
    email?: string;
    subscription?: SubscriptionData;
    featureFlags: string[];
};

const anonUser = (): User => ({ featureFlags: [] });

/*
 * Synchronously gets the last received user data, _without_
 * refreshing it in any way. After 7 days without a refresh
 * though, the result will change when the JWT expires.
 */
export function getLastUserData(): User {
    try {
        return parseUserData(localStorage.getItem('last_jwt'));
    } catch (e) {
        console.warn("Couldn't parse saved user data", e);
        return anonUser();
    }
}

/*
 * Get the latest valid user data we can. If possible, it loads the
 * latest data from the server. If that fails to load, or if it loads
 * but fails to parse, we return the latest user data.
 *
 * If there are no tokens available, or the latest data is expired,
 * this returns an empty (logged out) user.
 */
export async function getLatestUserData(): Promise<User> {
    const lastUserData = getLastUserData();

    return {
        email: '',
        // Use undefined rather than {} when there's any missing required sub fields
        subscription: {
            id: 1,
            status: 'active',
            plan: 'pro-perpetual',
            expiry: new Date(Date.now()),
            updateBillingDetailsUrl: '',
            cancelSubscriptionUrl: '',
            lastReceiptUrl: ''
        },
        featureFlags: ['docker', 'openapi']
    }
}

function parseUserData(userJwt: string | null): User {
    if (!userJwt) return anonUser();

    return {
        email: '',
        // Use undefined rather than {} when there's any missing required sub fields
        // Use undefined rather than {} when there's any missing required sub fields
        subscription: {
            id: 1,
            status: 'active',
            plan: 'pro-perpetual',
            expiry: new Date(Date.now()),
            updateBillingDetailsUrl: '',
            cancelSubscriptionUrl: '',
            lastReceiptUrl: ''
        },
        featureFlags: ['docker', 'openapi']
    };
}

async function requestUserData(): Promise<string> {

    return new Response().text();
}