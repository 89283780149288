import * as _ from 'lodash';
import { reportError } from '../../errors';
import { delay, doWhile } from '../../util/promise';

export interface SubscriptionPlan {
    id: number;
    name: string;
    prices?: {
        monthly: string;
        total: string;
    };
}

export const SubscriptionPlans = {
    'pro-monthly': { id: 550380, name: 'Pro (monthly)' } as SubscriptionPlan,
    'pro-annual': { id: 550382, name: 'Pro (annual)' } as SubscriptionPlan,
    'pro-perpetual': { id: 599788, name: 'Pro (perpetual)' } as SubscriptionPlan,
    'team-monthly': { id: 550789, name: 'Team (monthly)' } as SubscriptionPlan,
    'team-annual': { id: 550788, name: 'Team (annual)' } as SubscriptionPlan,
};

function formatPrice(currency: string, price: number) {
    return Number(price).toLocaleString(undefined, {
        style: "currency",
        currency: currency,
        minimumFractionDigits: _.round(price) === price ? 0 : 2,
        maximumFractionDigits: 2
    })
}

export type SubscriptionPlanCode = keyof typeof SubscriptionPlans;

export const getSubscriptionPlanCode = (id: number | undefined) =>
    _.findKey(SubscriptionPlans, { id: id }) as SubscriptionPlanCode | undefined;

export const openCheckout = async (email: string, planCode: SubscriptionPlanCode) => {
    window.open(
        `https://pay.paddle.com/checkout/${SubscriptionPlans[planCode].id
        }?guest_email=${encodeURIComponent(email)
        }&referring_domain=app.httptoolkit.tech`,
        '_blank'
    );
}