import * as _ from 'lodash';
import { observable, action, flow, computed, when } from 'mobx';

import { reportError, reportErrorsAsUser } from '../../errors';
import { trackEvent } from '../../tracking';
import { delay } from '../../util/promise';
import { lazyObservablePromise } from '../../util/observable';

import {
    User,
    getLatestUserData,
    getLastUserData,
    RefreshRejectedError
} from './auth';
import {
    SubscriptionPlans,
    SubscriptionPlanCode,
    openCheckout
} from './subscriptions';

export class AccountStore {

    constructor(
        private goToSettings: () => void
    ) { }

    readonly initialized = lazyObservablePromise(async () => {
        // Update account data automatically on login, logout & every 10 mins

        setInterval(this.updateUser, 1000 * 60 * 10);
        this.updateUser();

        console.log('Account store created');
    });

    @observable
    private user: User = getLastUserData();

    @observable
    accountDataLastUpdated = 0;

    @computed get userEmail() {
        return this.user.email;
    }

    @computed get userSubscription() {
        return this.isPaidUser || this.isPastDueUser
            ? this.user.subscription
            : undefined;
    }

    private updateUser = flow(function* (this: AccountStore) {
        this.user = yield getLatestUserData();
        this.accountDataLastUpdated = Date.now();

        // Include the user email in error reports whilst they're logged in.
        // Useful generally, but especially for checkout/subscription issues.
        reportErrorsAsUser(this.user.email);
    }.bind(this));

    readonly subscriptionPlans = SubscriptionPlans;

    @observable
    modal: 'login' | 'pick-a-plan' | 'post-checkout' | undefined;

    @observable
    private selectedPlan: SubscriptionPlanCode | undefined;

    @computed get isLoggedIn() {
        return !!this.user.email;
    }

    @computed get featureFlags() {
        return _.clone(this.user.featureFlags);
    }

    @computed private get isStatusUnexpired() {
        const subscriptionExpiry = this.user.subscription?.expiry;
        const subscriptionStatus = this.user.subscription?.status;

        const expiryMargin = subscriptionStatus === 'active'
            // If we're offline during subscription renewal, and the sub was active last
            // we checked, then we might just have outdated data, so leave extra slack.
            // This gives a week of offline usage. Should be enough, given that most HTTP
            // development needs network connectivity anyway.
            ? 1000 * 60 * 60 * 24 * 7
            : 0;

        return !!subscriptionExpiry &&
            subscriptionExpiry.valueOf() + expiryMargin > Date.now();
    }

    @computed get isPaidUser() {
        // ------------------------------------------------------------------
        // You could set this to true to become a paid user for free.
        // I'd rather you didn't. HTTP Toolkit takes time & love to build,
        // and I can't do that if it doesn't pay my bills!
        //
        // Fund open source - if you want Pro, help pay for its development.
        // Can't afford it? Get in touch: tim@httptoolkit.tech.
        // ------------------------------------------------------------------

        // If you're before the last expiry date, your subscription is valid,
        // unless it's past_due, in which case you're in a strange ambiguous
        // zone, and the expiry date is the next retry. In that case, your
        // status is unexpired, but _not_ considered as valid for Pro features.
        // Note that explicitly cancelled ('deleted') subscriptions are still
        // valid until the end of the last paid period though!
        return this.user.subscription?.status !== 'past_due' &&
            this.isStatusUnexpired;
    }

    @computed get isPastDueUser() {
        // Is the user a subscribed user whose payments are failing? Keep them
        // in an intermediate state so they can fix it (for now, until payment
        // retries fail, and their subscription cancels & expires completely).
        return this.user.subscription?.status === 'past_due' &&
            this.isStatusUnexpired;
    }

    @computed get userHasSubscription() {
        return this.isPaidUser || this.isPastDueUser;
    }

    @computed get mightBePaidUser() {
        // Like isPaidUser, but returns true for users who have subscription data
        // locally that's expired, until we successfully make a first check.
        return this.user.subscription?.status &&
            this.user.subscription?.status !== 'past_due' &&
            (this.isStatusUnexpired || this.accountDataLastUpdated === 0);
    }

}